/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "GeometosRounded";
  src: url("./assets/fonts/geometos/GeometosRounded.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/geometos/GeometosRounded.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/geometos/GeometosRounded.otf")
      format("opentype"),
    /* Open Type Font */ url("./assets/fonts/geometos/GeometosRounded.svg")
      format("svg"),
    /* Legacy iOS */ url("./assets/fonts/geometos/GeometosRounded.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/geometos/GeometosRounded.woff") format("woff"),
    /* Modern Browsers */ url("./assets/fonts/geometos/GeometosRounded.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0 auto;
  padding: 20px;
  max-width: 1000px;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #e8f2ff;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "GeometosRounded";
  color: #1c83ff;
}
