@import "../../assets/scss/variables";

.WeatherInfo {
  display: flex;
  flex-direction: column;
  font-family: "GeometosRounded";

  .time {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 28px;
  }

  .icon {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    svg {
      width: 80px;
      height: 80px;
    }
  }

  .temperature {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 28px;
  }

  .otherValues {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  > * {
    margin-top: 20px;
  }

  margin-bottom: 20px;

  @media (max-width: 500px) {
    flex-direction: row;
    flex-wrap: nowrap;

    > * {
      margin-top: 5px;
    }
    margin-bottom: 5px;

    .otherValues {
      display: none;
    }

    .time,
    .temperature {
      font-size: 16px;
    }

    .icon {
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
