@import "../../assets/scss/variables";

.Accordion {
  display: flex;
  flex: 1;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    font-family: "GeometosRounded";
    font-size: 16px;
    height: 50px;
    min-height: 50px;
    flex: 1;
    border: 2px solid $main-color;
    border-radius: $border-radius;
    background: $main-color-white;
    color: $main-color;
    transition: all 0.5s ease;

    &:hover {
      background: $main-color-bright;
      color: $main-color-white;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 0;
    border: none;
    overflow: hidden;
    border-radius: $border-radius;
    border: 0 solid $main-color;

    @media (max-width: 500px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  &__open {
    .title {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .content {
      height: auto;
      padding: 10px;
      border: 2px solid $main-color;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      @media (max-width: 500px) {
        padding: 2px;
      }
    }
  }
}
