@import "../../assets/scss/variables";

.Button {
  outline: none;
  border: none;
  min-width: 100px;

  font-family: "GeometosRounded";
  background-color: $main-color;
  border-radius: $border-radius;
  overflow: hidden;
  color: $main-color-white;

  &:hover {
    background-color: $main-color-darker;
    cursor: pointer;
  }

  &:active {
    background-color: $main-color-dark;
  }
}
