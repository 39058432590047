@import "../../assets/scss/variables";

.Card {
  padding: 10px;
  background: transparent;
  .visibleCard {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    background: $main-color-bright;
    color: $main-color-white;
    box-shadow: 3px 3px 2px 1px rgba(0, 0, 255, 0.2);

    &:hover {
      background: $main-color-brighter;
    }
  }

  @media (max-width: 500px) {
    min-width: unset;
    flex: 1;
    padding: 2px 0;
  }
}
