@import "../../assets/scss/variables";

.Input {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 50px;

  .input {
    font-family: "GeometosRounded";
    height: 35px;
    outline: none;
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 0 5px;

    &:hover {
      border: 1px solid $main-color-brighter;
    }
  }
}
