@import "../../assets/scss/variables";

.CurrentWeather {
  display: flex;
  border: 2px solid $main-color;
  font-family: "GeometosRounded";
  flex-wrap: wrap;

  .leftContainer {
    flex: 0 0 calc(50% - 20px);
    display: flex;
    flex-wrap: nowrap;
    padding: 10px;

    .icon {
      display: flex;
      flex: 0 0 80px;
      justify-content: flex-start;
      align-items: center;
      color: $main-color;

      svg {
        width: 80px;
        height: 80px;
      }
    }

    .temperature {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      color: $main-color;
      font-size: 40px;
    }

    @media (max-width: 500px) {
      flex: 1 1 100%;
    }
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    flex: 0 0 calc(50% - 20px);

    .otherValues {
      color: $main-color;
    }

    @media (max-width: 500px) {
      flex: 1 1 100%;
      align-items: center;
    }
  }
}
