$main-color: #1c83ff;
$main-color-darker: #1975e5;
$main-color-dark: #1668cc;
$main-color-brighter: #328fff;
$main-color-bright: #499bff;
$main-color-white: #e8f2ff;
$main-color-black: #020d19;
$color-error: #ff3a1c;

$border-radius: 3px;
