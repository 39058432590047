@import "../../assets/scss/variables";

.savedCitiesList {
  display: flex;
  flex-direction: column;

  .savedCity {
    height: 40px;
    border: 2px solid $main-color;
    margin: 5px 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 18px;
    color: $main-color;
    font-family: "GeoMetosRounded";

    &:hover {
      cursor: pointer;
      background: $main-color;
      color: $main-color-white;
    }
  }
}
