@import "./assets/scss/variables";

.App {
  display: flex;
  flex-direction: column;
  color: $main-color-black;

  @media (max-width: 500px) {
    h1 {
      font-size: 16px;
      text-align: center;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 14px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }

  .line {
    flex: 1;
    display: flex;
    justify-content: center;

    > * {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .error {
    margin: 20px 0;
    font-family: "GeometosRounded";
    font-size: 20px;
    color: $color-error;
  }
}
